import {
  CustomField,
  IndividualRoleEnum,
  FieldPropertyTypeEnum,
  FieldTypeEnum,
} from 'shared-domain';

export const individualFields: CustomField[] = [
  {
    // This field is mandatory to create an individual
    id: 'first_name',
    isRequired: true,
    isEnabled: true,
  },
  {
    id: 'middle_name',
    isRequired: false,
    isEnabled: false,
  },
  {
    // This field is mandatory to create an individual
    id: 'last_name',
    isRequired: true,
    isEnabled: true,
  },
  {
    id: 'maiden_name',
    isRequired: false,
    isEnabled: false,
  },
  {
    id: 'birth_date',
    type: FieldTypeEnum.date,
    isRequired: true,
    isEnabled: true,
  },
  {
    id: 'birth_country',
    type: FieldTypeEnum.country,
    isRequired: true,
    isEnabled: true,
  },
  {
    id: 'position',
    isRequired: true,
    isEnabled: true,
  },
  {
    id: 'street_address',
    nested: 'address',
    isRequired: true,
    isEnabled: true,
  },
  {
    id: 'street_address_2',
    nested: 'address',
    isRequired: false,
    isEnabled: true,
  },
  {
    id: 'postal_code',
    nested: 'address',
    isRequired: false,
    isEnabled: true,
  },
  {
    id: 'city',
    nested: 'address',
    isRequired: true,
    isEnabled: true,
  },
  {
    id: 'state',
    nested: 'address',
    isRequired: false,
    isEnabled: false,
  },
  {
    id: 'region',
    nested: 'address',
    isRequired: false,
    isEnabled: false,
  },
  {
    id: 'country',
    nested: 'address',
    type: FieldTypeEnum.country,
    isRequired: false,
    isEnabled: true,
  },
  {
    // This field is mandatory to create an individual with the "applicant" role
    id: 'email',
    type: FieldTypeEnum.email,
    isRequired: true,
    isEnabled: true,
  },
  {
    id: 'phone_number',
    type: FieldTypeEnum.tel,
    isRequired: true,
    isEnabled: true,
  },
  {
    // This field is mandatory to create an individual in a KYB
    id: 'roles',
    type: FieldTypeEnum.checkbox,
    isRequired: true,
    isEnabled: true,
    options: [
      IndividualRoleEnum.beneficial_owner,
      IndividualRoleEnum.legal_representative,
    ],
  },
  {
    id: 'ownership_percentage',
    type: FieldTypeEnum.number,
    isRequired: false,
    isEnabled: true,
  },
  {
    id: 'holdings',
    type: FieldTypeEnum.affiliated,
    isRequired: false,
    isEnabled: true,
    options: [],
  },
  {
    id: 'designated_signatory',
    type: FieldTypeEnum.radio,
    options: ['true', 'false'],
    isRequired: true,
    isEnabled: true,
    propertyType: FieldPropertyTypeEnum.custom,
  },
  {
    id: 'individual_is_us_citizen_or_taxpayer',
    type: FieldTypeEnum.radio,
    options: ['true', 'false'],
    isRequired: true,
    isEnabled: true,
    propertyType: FieldPropertyTypeEnum.custom,
  },
  {
    id: 'individual_is_politically_exposed',
    hasHelper: true,
    type: FieldTypeEnum.radio,
    options: ['true', 'false'],
    isRequired: true,
    isEnabled: true,
    propertyType: FieldPropertyTypeEnum.custom,
  },
  {
    id: 'country_associated_with_the_pep_activities',
    type: FieldTypeEnum.country,
    isMulti: true,
    isRequired: false,
    isEnabled: true,
    propertyType: FieldPropertyTypeEnum.custom,
  },
  {
    id: 'level_and_nature_of_peps_authority_or_influence_over_government_activities_or_officials',
    type: FieldTypeEnum.textarea,
    hasHelper: true,
    isRequired: false,
    isEnabled: true,
    propertyType: FieldPropertyTypeEnum.custom,
  },
  {
    id: 'tax_identification_number',
    isRequired: false,
    isEnabled: false,
  },
  {
    id: 'social_security_number',
    isRequired: false,
    isEnabled: false,
  },
  {
    id: 'birth_place',
    isRequired: false,
    isEnabled: false,
  },
  {
    id: 'iban',
    nested: 'banking_information',
    isRequired: false,
    isEnabled: false,
  },
  {
    id: 'bic',
    nested: 'banking_information',
    isRequired: false,
    isEnabled: false,
  },
];
