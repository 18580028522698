import { useMemo } from 'react';
import { Box, Button, Input, VStack } from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { CountrySelect, GroupController, Radio } from 'frontend-components';
import { useStepId, useStore, useSubmitCustomStepForm } from 'frontend-common';

const has_physical_address = 'Do you have a physical address?';
const is_same_as_registration_address =
  'Is your physical address the same as your registration address?';
const address_line_1 = 'Address line 1';
const address_line_2 = 'Address line 2';
const postal_code = 'Postal code';
const city = 'City';
const state = 'State';
const country = 'Country';

const validationSchema = z
  .object({
    [has_physical_address]: z.union([z.literal('Yes'), z.literal('No')]),
    [is_same_as_registration_address]: z
      .union([z.literal('Yes'), z.literal('No'), z.literal('')])
      .transform((e) => (e === '' ? undefined : e))
      .optional(),
    [address_line_1]: z.string(),
    [address_line_2]: z.string().optional(),
    [postal_code]: z.string(),
    [city]: z.string(),
    [state]: z.string().optional(),
    [country]: z.string(),
  })
  .superRefine((values, context) => {
    if (
      values[has_physical_address] &&
      values[has_physical_address] === 'Yes'
    ) {
      if (!values[is_same_as_registration_address]) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Please complete this mandatory field.',
          path: [is_same_as_registration_address],
        });
      }
    }

    if (
      values[is_same_as_registration_address] &&
      values[is_same_as_registration_address] === 'No'
    ) {
      if (!values[address_line_1]) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Please complete this mandatory field.',
          path: [address_line_1],
        });
      }
      if (!values[postal_code]) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Please complete this mandatory field.',
          path: [postal_code],
        });
      }
      if (!values[city]) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Please complete this mandatory field.',
          path: [city],
        });
      }
      if (!values[country]) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Please complete this mandatory field.',
          path: [country],
        });
      }
    }
  });

export const AdditionalAddressDetails = () => {
  const stepId = useStepId();

  const { t } = useTranslation();
  const { submitCustomStepForm } = useSubmitCustomStepForm();
  const { metadata } = useStore();

  const defaultValues = useMemo(() => {
    return {
      [has_physical_address]: metadata?.[has_physical_address] || '',
      [is_same_as_registration_address]:
        metadata?.[is_same_as_registration_address] || '',
      [address_line_1]: metadata?.[address_line_1] || '',
      [address_line_2]: metadata?.[address_line_2] || '',
      [postal_code]: metadata?.[postal_code] || '',
      [city]: metadata?.[city] || '',
      [state]: metadata?.[state] || '',
      [country]: metadata?.[country] || '',
    };
  }, [metadata]);

  const methods = useForm<any>({
    mode: 'all',
    criteriaMode: 'all',
    resolver: zodResolver(validationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    resetField,
    formState: { isValid, isSubmitting },
  } = methods;

  const onSubmit: SubmitHandler<any> = async (formData) => {
    submitCustomStepForm({ caseMetadata: formData });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing="6" alignItems="start">
        <GroupController
          name={has_physical_address}
          label={t(`steps.${stepId}.${has_physical_address}.label`)}
          helper={t(`steps.${stepId}.${has_physical_address}.helper`)}
          isRequired={true}
          control={control}
          render={(f) => (
            <Radio
              stepId={stepId}
              name={has_physical_address}
              onChange={(value: string) => {
                resetField(is_same_as_registration_address);
                resetField(address_line_1);
                resetField(address_line_2);
                resetField(postal_code);
                resetField(city);
                resetField(state);
                resetField(country);
                setValue(has_physical_address, value ?? '', {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
              options={['Yes', 'No']}
              defaultValue={f.value}
            />
          )}
        />

        {watch(has_physical_address) === 'Yes' && (
          <GroupController
            name={is_same_as_registration_address}
            label={t(
              `steps.${stepId}.${is_same_as_registration_address}.label`,
            )}
            isRequired={true}
            control={control}
            render={(f) => (
              <Radio
                stepId={stepId}
                name={is_same_as_registration_address}
                onChange={(value: string) => {
                  resetField(address_line_1);
                  resetField(address_line_2);
                  resetField(postal_code);
                  resetField(city);
                  resetField(state);
                  resetField(country);
                  setValue(is_same_as_registration_address, value ?? '', {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                }}
                options={['Yes', 'No']}
                defaultValue={f.value}
              />
            )}
          />
        )}

        {watch(has_physical_address) === 'Yes' &&
          watch(is_same_as_registration_address) === 'No' && (
            <>
              <GroupController
                name={address_line_1}
                label={t(`steps.${stepId}.${address_line_1}.label`)}
                isRequired={true}
                control={control}
                render={(f) => {
                  return <Input type="text" maxW="400px" {...f} />;
                }}
              />
              <GroupController
                name={address_line_2}
                label={t(`steps.${stepId}.${address_line_2}.label`)}
                isRequired={false}
                control={control}
                render={(f) => {
                  return <Input type="text" maxW="400px" {...f} />;
                }}
              />
              <GroupController
                name={postal_code}
                label={t(`steps.${stepId}.${postal_code}.label`)}
                isRequired={true}
                control={control}
                render={(f) => {
                  return <Input type="text" maxW="400px" {...f} />;
                }}
              />
              <GroupController
                name={city}
                label={t(`steps.${stepId}.${city}.label`)}
                isRequired={true}
                control={control}
                render={(f) => {
                  return <Input type="text" maxW="400px" {...f} />;
                }}
              />
              <GroupController
                name={state}
                label={t(`steps.${stepId}.${state}.label`)}
                isRequired={false}
                control={control}
                render={(f) => {
                  return <Input type="text" maxW="400px" {...f} />;
                }}
              />
              <GroupController
                name={country}
                label={t(`steps.${stepId}.${country}.label`)}
                isRequired={true}
                control={control}
                render={(f) => (
                  <CountrySelect
                    onChange={(value: string[]) => {
                      setValue(country, value ?? [], {
                        shouldDirty: true,
                        shouldValidate: true,
                      });
                    }}
                    defaultValue={f.value}
                    isMulti={false}
                  />
                )}
              />
            </>
          )}

        <Box>
          <Button
            variant="next"
            isLoading={isSubmitting}
            isDisabled={!isValid}
            type="submit"
          >
            {t('domain.form.next')}
          </Button>
        </Box>
      </VStack>
    </form>
  );
};
