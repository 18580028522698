import { useMemo } from 'react';
import { Box, Button, Input, VStack } from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Checkbox, GroupController, Radio } from 'frontend-components';
import { useStepId, useStore, useSubmitCustomStepForm } from 'frontend-common';

const business_type = 'Business type';
const business_type_options = [
  'Corporation not publicly listed',
  'Non-profit organization',
  'Foundation',
  'Fund or Fiduciary entity',
  'Sole Proprietorship',
  'Limited Liability Company (LLC)',
  'Other',
];
const business_type_other = 'Business type (other)';
const active_dao = 'Is your project an active DAO?';
const dao_governance = 'Please describe your DAO governance';
const dao_holders =
  'Please indicate the biggest holders or the persons entitled to vote';
const regulated_company = 'Are you a regulated Company?';
const regulated_licence = 'Describe your license type and regulatory authority';
const regulated_policies =
  'Do you have AML and/or KYC policies or use a provider for these purposes?';
const regulated_policies_options = [
  'AML/KYC policies',
  'A provider agreement',
  'Neither',
];

const validationSchema = Yup.object({
  [business_type]: Yup.array(Yup.mixed().oneOf(business_type_options))
    .min(1)
    .required(),
  [business_type_other]: Yup.string().when(business_type, {
    is: (val: string[]) => val.includes('Other'),
    then: (schema) => schema.required(),
    otherwise: (schema) =>
      schema
        .optional()
        .nullable()
        .default(null)
        .transform(() => null),
  }),
  [active_dao]: Yup.string().oneOf(['Yes', 'No']).required(),
  [dao_governance]: Yup.string().when(active_dao, {
    is: (val: string) => val === 'Yes',
    then: (schema) => schema.required(),
    otherwise: (schema) =>
      schema
        .optional()
        .nullable()
        .default(null)
        .transform(() => null),
  }),
  [dao_holders]: Yup.string().when(active_dao, {
    is: (val: string) => val === 'Yes',
    then: (schema) => schema.required(),
    otherwise: (schema) =>
      schema
        .optional()
        .nullable()
        .default(null)
        .transform(() => null),
  }),
  [regulated_company]: Yup.string().oneOf(['Yes', 'No']).required(),
  [regulated_licence]: Yup.string().when(regulated_company, {
    is: (val: string) => val === 'Yes',
    then: (schema) => schema.required(),
    otherwise: (schema) =>
      schema
        .optional()
        .nullable()
        .default(null)
        .transform(() => null),
  }),
  [regulated_policies]: Yup.string()
    .oneOf(regulated_policies_options)
    .when(regulated_company, {
      is: (val: string) => val === 'No',
      then: (schema) => schema.required(),
      otherwise: (schema) =>
        schema
          .optional()
          .nullable()
          .default(null)
          .transform(() => null),
    }),
});

export const CompanyDetails = () => {
  const stepId = useStepId();

  const { t } = useTranslation();
  const { submitCustomStepForm } = useSubmitCustomStepForm();
  const { metadata } = useStore();

  const defaultValues = useMemo(() => {
    return {
      [business_type]: metadata?.[business_type] || '',
      [business_type_other]: metadata?.[business_type_other] || '',
      [active_dao]: metadata?.[active_dao] || '',
      [dao_governance]: metadata?.[dao_governance] || '',
      [dao_holders]: metadata?.[dao_holders] || '',
      [regulated_company]: metadata?.[regulated_company] || '',
      [regulated_licence]: metadata?.[regulated_licence] || '',
      [regulated_policies]: metadata?.[regulated_policies] || '',
    };
  }, [metadata]);

  const methods = useForm<any>({
    mode: 'all',
    criteriaMode: 'all',
    // @TODO - OPS-9 - Replace Yup by Zod
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { isValid, isSubmitting },
  } = methods;

  const onSubmit: SubmitHandler<any> = async (formData) => {
    submitCustomStepForm({ caseMetadata: formData });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing="6" alignItems="start">
        <GroupController
          name={business_type}
          label={t(`steps.${stepId}.${business_type}.label`)}
          control={control}
          render={(f) => (
            <Checkbox
              stepId={stepId}
              name={business_type}
              onChange={(values: string[]) => {
                setValue(business_type, values ?? [], {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
              options={business_type_options}
              defaultValue={f.value}
            />
          )}
        />

        {watch(business_type).includes('Other') && (
          <GroupController
            name={business_type_other}
            label={t(`steps.${stepId}.${business_type_other}.label`)}
            isRequired={true}
            control={control}
            render={(f) => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              return <Input type="text" maxW="400px" {...f} />;
            }}
          />
        )}

        <GroupController
          name={active_dao}
          label={t(`steps.${stepId}.${active_dao}.label`)}
          isRequired={true}
          control={control}
          render={(f) => (
            <Radio
              stepId={stepId}
              name={active_dao}
              onChange={(value: string) => {
                setValue(active_dao, value ?? '', {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
              options={['Yes', 'No']}
              defaultValue={f.value}
            />
          )}
        />

        {watch(active_dao) === 'Yes' && (
          <>
            <GroupController
              name={dao_governance}
              label={t(`steps.${stepId}.${dao_governance}.label`)}
              helper={t(`steps.${stepId}.${dao_governance}.helper`)}
              isRequired={true}
              control={control}
              render={(f) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                return <Input type="text" maxW="400px" {...f} />;
              }}
            />
            <GroupController
              name={dao_holders}
              label={t(`steps.${stepId}.${dao_holders}.label`)}
              isRequired={true}
              control={control}
              render={(f) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                return <Input type="text" maxW="400px" {...f} />;
              }}
            />
          </>
        )}

        <GroupController
          name={regulated_company}
          label={t(`steps.${stepId}.${regulated_company}.label`)}
          isRequired={true}
          control={control}
          render={(f) => (
            <Radio
              stepId={stepId}
              name={regulated_company}
              onChange={(value: string) => {
                setValue(regulated_company, value ?? '', {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
              options={['Yes', 'No']}
              defaultValue={f.value}
            />
          )}
        />

        {watch(regulated_company) === 'Yes' && (
          <GroupController
            name={regulated_licence}
            label={t(`steps.${stepId}.${regulated_licence}.label`)}
            isRequired={true}
            control={control}
            render={(f) => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              return <Input type="text" maxW="400px" {...f} />;
            }}
          />
        )}

        {watch(regulated_company) === 'No' && (
          <GroupController
            name={regulated_policies}
            label={t(`steps.${stepId}.${regulated_policies}.label`)}
            isRequired={true}
            control={control}
            render={(f) => (
              <Radio
                stepId={stepId}
                name={regulated_policies}
                onChange={(value: string) => {
                  setValue(regulated_policies, value ?? '', {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                }}
                options={regulated_policies_options}
                defaultValue={f.value}
              />
            )}
          />
        )}

        <Box>
          <Button
            variant="next"
            isLoading={isSubmitting}
            isDisabled={!isValid}
            type="submit"
          >
            {t('domain.form.next')}
          </Button>
        </Box>
      </VStack>
    </form>
  );
};
