import { BaseStepIdEnum, StepConfig, StepTypeEnum } from 'shared-domain';
import { Disclaimer } from '../app/components/disclaimer';
import { CompanyDetails } from '../app/components/company-details';
import { NominatedBankingAccount } from '../app/components/nominated-banking-account';
import { AdditionalAddressDetails } from '../app/components/additional-address-details';
import { SourceOfFunds } from '../app/components/source-of-funds';

export const stepsConfig: StepConfig[] = [
  {
    id: 'disclaimer',
    type: StepTypeEnum.custom_component,
    component: Disclaimer,
  },
  {
    id: BaseStepIdEnum.company_search,
  },
  {
    id: BaseStepIdEnum.company_list,
  },
  {
    id: BaseStepIdEnum.company_edit,
  },
  {
    id: 'additional_address_details',
    type: StepTypeEnum.custom_component,
    component: AdditionalAddressDetails,
  },
  {
    id: 'company_details',
    type: StepTypeEnum.custom_component,
    component: CompanyDetails,
  },
  {
    id: BaseStepIdEnum.affiliated_companies_list,
  },
  {
    id: BaseStepIdEnum.individuals_list,
    config: { hasApplicant: false },
  },
  {
    id: 'source_of_funds',
    type: StepTypeEnum.custom_component,
    component: SourceOfFunds,
  },
  {
    id: 'nominated_banking_account',
    type: StepTypeEnum.custom_component,
    component: NominatedBankingAccount,
  },
  {
    id: 'final_confirmation',
    type: StepTypeEnum.text,
  },
];
