import { useMemo } from 'react';
import { Box, Button, Input, VStack, Textarea, Text } from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { GroupController, Select, Checkbox } from 'frontend-components';
import { useStepId, useStore, useSubmitCustomStepForm } from 'frontend-common';

const type_of_operations = 'Type of operations';
const type_of_operations_options = [
  'Market making',
  'OTC services',
  'Derivatives',
  'Other',
];
const type_of_operations_other = 'Other type of operations';
const source_of_wealth = 'Source of wealth';
const source_of_wealth_options = [
  'Company Revenue / Employment Income',
  'Token Sale',
  'Fundraising',
  'Assets Sales',
  'Donations / Grants',
  'Funding from investors or founders',
  'Family Wealth / Inheritance',
];
const source_of_funds = 'Source of funds';
const source_of_funds_options = [
  'Company Treasury / Personal savings',
  'Token Sale',
  'Fundraising',
  'Assets Sales',
  'Trading profits',
  'Mining / Staking / Farming / Lending',
  'Gifts / Rewards / Airdrops',
  'Gambling',
];
const storage_of_the_funds = 'Storage of the funds';
const storage_of_the_funds_options = [
  'Non custodial wallet',
  'Custodial wallet',
  'Bank',
  'Not applicable',
];
const wallet_addresses =
  'Wallet addresses where the funds are currently stored';
const expected_average_value_per_trade = 'Expected average value per trade';
const expected_average_value_per_trade_options = [
  'Less than 500K',
  '500K - 1M',
  '1M - 5M',
  '5M - 10M',
  '10M or more',
];
const expected_number_of_trades_per_months =
  'Expected number of trades per month';
const expected_number_of_trades_per_months_options = [
  'Less than 5',
  'From 5 to 20',
  'More than 20',
];
const main_nature_of_the_operations = 'Main nature of the operation';
const main_nature_of_the_operations_options = [
  'On-ramping / Off-ramping',
  'Crypto to Crypto',
];
const currencies_expected_to_trade = 'Currencies expected to trade';

const validationSchema = Yup.object({
  [type_of_operations]: Yup.array().min(1).label('This'),
  [type_of_operations_other]: Yup.string().when(type_of_operations, {
    is: (val: string[]) => val.includes('Other'),
    then: (schema) => schema.required().label('This'),
    otherwise: (schema) =>
      schema
        .optional()
        .nullable()
        .default(null)
        .transform(() => null),
  }),
  [source_of_wealth]: Yup.string()
    .oneOf([...source_of_wealth_options, 'other'])
    .required(),
  [`${source_of_wealth}_other`]: Yup.string().when(source_of_wealth, {
    is: (val: string) => val === 'other',
    then: (schema) => schema.required(),
    otherwise: (schema) =>
      schema
        .optional()
        .nullable()
        .default(null)
        .transform(() => null),
  }),
  [source_of_funds]: Yup.string()
    .oneOf([...source_of_funds_options, 'other'])
    .required(),
  [`${source_of_funds}_other`]: Yup.string().when(source_of_funds, {
    is: (val: string) => val === 'other',
    then: (schema) => schema.required(),
    otherwise: (schema) =>
      schema
        .optional()
        .nullable()
        .default(null)
        .transform(() => null),
  }),
  [storage_of_the_funds]: Yup.string().required().label('This'),
  [wallet_addresses]: Yup.string().when(storage_of_the_funds, {
    is: (val: string) =>
      val === 'Custodial wallet' || val === 'Non custodial wallet',
    then: (schema) => schema.required().label('This'),
    otherwise: (schema) =>
      schema
        .optional()
        .nullable()
        .default(null)
        .transform(() => null),
  }),
  [expected_average_value_per_trade]: Yup.string().when(type_of_operations, {
    is: (val: string[]) => val.includes('OTC services'),
    then: (schema) => schema.required().label('This'),
    otherwise: (schema) =>
      schema
        .optional()
        .nullable()
        .default(null)
        .transform(() => null),
  }),
  [expected_number_of_trades_per_months]: Yup.string().when(
    type_of_operations,
    {
      is: (val: string[]) => val.includes('OTC services'),
      then: (schema) => schema.required().label('This'),
      otherwise: (schema) =>
        schema
          .optional()
          .nullable()
          .default(null)
          .transform(() => null),
    },
  ),
  [main_nature_of_the_operations]: Yup.string().when(type_of_operations, {
    is: (val: string[]) => val.includes('OTC services'),
    then: (schema) => schema.required().label('This'),
    otherwise: (schema) =>
      schema
        .optional()
        .nullable()
        .default(null)
        .transform(() => null),
  }),
  [currencies_expected_to_trade]: Yup.string()
    .max(250)
    .when(type_of_operations, {
      is: (val: string[]) => val.includes('OTC services'),
      then: (schema) => schema.required().label('This'),
      otherwise: (schema) =>
        schema
          .optional()
          .nullable()
          .default(null)
          .transform(() => null),
    }),
});

export const SourceOfFunds = () => {
  const stepId = useStepId();

  const { t } = useTranslation();
  const { submitCustomStepForm } = useSubmitCustomStepForm();
  const { metadata } = useStore();

  const defaultValues = useMemo(() => {
    return {
      [type_of_operations]: metadata?.[type_of_operations] || '',
      [source_of_wealth]: metadata?.[source_of_wealth] || '',
      [`${source_of_wealth}_other`]:
        metadata?.[`${source_of_wealth}_other`] || '',
      [source_of_funds]: metadata?.[source_of_funds] || '',
      [`${source_of_funds}_other`]:
        metadata?.[`${source_of_funds}_other`] || '',
      [storage_of_the_funds]: metadata?.[storage_of_the_funds] || '',
      [wallet_addresses]: metadata?.[wallet_addresses] || '',
      [expected_average_value_per_trade]:
        metadata?.[expected_average_value_per_trade] || '',
      [expected_number_of_trades_per_months]:
        metadata?.[expected_number_of_trades_per_months] || '',
      [main_nature_of_the_operations]:
        metadata?.[main_nature_of_the_operations] || '',
      [currencies_expected_to_trade]:
        metadata?.[currencies_expected_to_trade] || '',
    };
  }, [metadata]);

  const methods = useForm<any>({
    mode: 'all',
    criteriaMode: 'all',
    // @TODO - OPS-9 - Replace Yup by Zod
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    resetField,
    formState: { isValid, isSubmitting },
  } = methods;

  const onSubmit: SubmitHandler<any> = async (formData) => {
    submitCustomStepForm({ caseMetadata: formData });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing="6" alignItems="start">
        <Box w="100%">
          <GroupController
            name={type_of_operations}
            label={t(`steps.${stepId}.${type_of_operations}.label`)}
            control={control}
            render={(f) => (
              <Checkbox
                stepId={stepId}
                name={type_of_operations}
                onChange={(value: string[]) => {
                  resetField(expected_average_value_per_trade);
                  resetField(expected_number_of_trades_per_months);
                  resetField(main_nature_of_the_operations);
                  resetField(currencies_expected_to_trade);
                  setValue(type_of_operations, value ?? '', {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                }}
                options={type_of_operations_options}
                defaultValue={f.value}
              />
            )}
          />
          {watch(type_of_operations).includes('Other') && (
            <GroupController
              mt={5}
              name={`${type_of_operations_other}`}
              label={t(`steps.${stepId}.${type_of_operations_other}.label`)}
              isRequired={true}
              control={control}
              render={(f) => {
                return (
                  <Input
                    type="text"
                    placeholder={t(
                      `steps.${stepId}.${type_of_operations_other}.placeholder`,
                    )}
                    maxW="400px"
                    {...f}
                  />
                );
              }}
            />
          )}
        </Box>

        <Box w="100%">
          <Text>
            <Text as="b" fontSize="xl" marginTop={6}>
              Source of Wealth and Funds
            </Text>
            <br />
            Keep in mind that documentation proving all information here
            provided will be requested in the next section. Data must be aligned
            with the operation requested
          </Text>
        </Box>

        <Box w="100%">
          <GroupController
            name={source_of_wealth}
            label={t(`steps.${stepId}.${source_of_wealth}.label`)}
            helper={t(`steps.${stepId}.${source_of_wealth}.helper`)}
            isRequired={true}
            control={control}
            render={(f) => (
              <Select
                stepId={stepId}
                name={source_of_wealth}
                onChange={(value: string) => {
                  setValue(source_of_wealth, value ?? '', {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                }}
                options={source_of_wealth_options}
                defaultValue={f.value}
                hasOtherOption={true}
              />
            )}
          />
          {watch(source_of_wealth) &&
            watch(source_of_wealth).includes('other') && (
              <GroupController
                mt={5}
                name={`${source_of_wealth}_other`}
                label={t(
                  `steps.${stepId}.${source_of_wealth}.other_option.label`,
                )}
                isRequired={true}
                control={control}
                render={(f) => {
                  return <Input type="text" maxW="400px" {...f} />;
                }}
              />
            )}
        </Box>

        <Box w="100%">
          <GroupController
            name={source_of_funds}
            label={t(`steps.${stepId}.${source_of_funds}.label`)}
            helper={t(`steps.${stepId}.${source_of_funds}.helper`)}
            isRequired={true}
            control={control}
            render={(f) => (
              <Select
                stepId={stepId}
                name={source_of_funds}
                onChange={(value: string) => {
                  setValue(source_of_funds, value ?? '', {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                }}
                options={source_of_funds_options}
                defaultValue={f.value}
                hasOtherOption={true}
              />
            )}
          />
          {watch(source_of_funds) &&
            watch(source_of_funds).includes('other') && (
              <GroupController
                mt={5}
                name={`${source_of_funds}_other`}
                label={t(
                  `steps.${stepId}.${source_of_funds}.other_option.label`,
                )}
                isRequired={true}
                control={control}
                render={(f) => {
                  return <Input type="text" maxW="400px" {...f} />;
                }}
              />
            )}
        </Box>

        <GroupController
          name={storage_of_the_funds}
          label={t(`steps.${stepId}.${storage_of_the_funds}.label`)}
          isRequired={true}
          control={control}
          render={(f) => (
            <Select
              stepId={stepId}
              name={storage_of_the_funds}
              onChange={(value: string) => {
                resetField(wallet_addresses);
                setValue(storage_of_the_funds, value ?? '', {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
              options={storage_of_the_funds_options}
              defaultValue={f.value}
            />
          )}
        />

        {['Custodial wallet', 'Non custodial wallet'].includes(
          watch(storage_of_the_funds),
        ) && (
          <GroupController
            name={wallet_addresses}
            label={t(`steps.${stepId}.${wallet_addresses}.label`)}
            helper={t(`steps.${stepId}.${wallet_addresses}.helper`)}
            isRequired={true}
            control={control}
            render={(f) => {
              return <Textarea maxW="400px" resize="vertical" {...f} />;
            }}
          />
        )}

        {watch(type_of_operations).includes('OTC services') && (
          <>
            <Box w="100%" marginTop={6}>
              <Text as="b" fontSize="xl">
                OTC services additional information
              </Text>
            </Box>

            <GroupController
              name={expected_average_value_per_trade}
              label={t(
                `steps.${stepId}.${expected_average_value_per_trade}.label`,
              )}
              isRequired={true}
              control={control}
              render={(f) => (
                <Select
                  stepId={stepId}
                  name={expected_average_value_per_trade}
                  onChange={(value: string) => {
                    setValue(expected_average_value_per_trade, value ?? '', {
                      shouldDirty: true,
                      shouldValidate: true,
                    });
                  }}
                  options={expected_average_value_per_trade_options}
                  defaultValue={f.value}
                />
              )}
            />
            <GroupController
              name={expected_number_of_trades_per_months}
              label={t(
                `steps.${stepId}.${expected_number_of_trades_per_months}.label`,
              )}
              isRequired={true}
              control={control}
              render={(f) => (
                <Select
                  stepId={stepId}
                  name={expected_number_of_trades_per_months}
                  onChange={(value: string) => {
                    setValue(
                      expected_number_of_trades_per_months,
                      value ?? '',
                      {
                        shouldDirty: true,
                        shouldValidate: true,
                      },
                    );
                  }}
                  options={expected_number_of_trades_per_months_options}
                  defaultValue={f.value}
                />
              )}
            />
            <GroupController
              name={main_nature_of_the_operations}
              label={t(
                `steps.${stepId}.${main_nature_of_the_operations}.label`,
              )}
              isRequired={true}
              control={control}
              render={(f) => (
                <Select
                  stepId={stepId}
                  name={main_nature_of_the_operations}
                  onChange={(value: string) => {
                    setValue(main_nature_of_the_operations, value ?? '', {
                      shouldDirty: true,
                      shouldValidate: true,
                    });
                  }}
                  options={main_nature_of_the_operations_options}
                  defaultValue={f.value}
                />
              )}
            />
            <GroupController
              name={currencies_expected_to_trade}
              label={t(`steps.${stepId}.${currencies_expected_to_trade}.label`)}
              helper={t(
                `steps.${stepId}.${currencies_expected_to_trade}.helper`,
              )}
              isRequired={true}
              control={control}
              render={(f) => {
                return <Input type="text" maxW="400px" {...f} />;
              }}
            />
          </>
        )}

        <Box>
          <Button
            variant="next"
            isLoading={isSubmitting}
            isDisabled={!isValid}
            type="submit"
          >
            {t('domain.form.next')}
          </Button>
        </Box>
      </VStack>
    </form>
  );
};
